<template>
  <div v-loading = "pageloading">
    <el-card class="cardfixed overshow" v-if="!isnotQYWX">
      <div style="height:80px;background:rgb(245,245,245)" class = "flexRow betweenCenter">
        <div class = "flexRow">
          <div style="margin-left:15px">
            <img src="https://cdn.dkycn.cn/melyshop/image/20210831-4f5edce2-1c97-404b-bef6-8cfdf7a428e2.png"  style="width:46px;hegith:46px" alt="">
          </div>
          <div style="margin-left:10px;" class = "flexCol flexevenly">
            <div vlass = "theFont" style="font-size: 14px;font-weight:bold">群裂变</div>
            <div class = "theFont">创建群裂变活动，引导客户群群主给群内成员发送活动邀请，通过以旧带新的方式，快速裂变客户群内成员数量</div>
          </div>
        </div>
        <el-button type="primary" style="margin-right:15px" @click="jumplink">授权安装企店助手</el-button>
      </div>
      <div style="margin-top:25px">
				<div class="desc-title">1/功能说明</div>
				<img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210831-3145360a-1496-4e59-90cb-c68c64c27f1e.png">
				<div class="desc-title">2/活动配置</div>
				<img style="display: block;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210831-c62cb35c-95b8-484d-a59a-011334cb23a1.png">
        <div class="desc-title">3/客户参与活动</div>
				<img style="display: block;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210831-c25accb7-521f-42a1-b5f7-90857becaaaf.png">
			</div>
    </el-card>
    <div v-else>
      <el-card body-style = "padding:10px">
        <div class = "flexRow betweenCenter">
          <div class = "titFont" style="font-weight:bold">群裂变活动：</div>
          <qr-codepop :downloadData="experienceCode" :downFont="'功能体验码'"></qr-codepop>
        </div>
        <div class = "flexRow" style="flex-wrap:wrap;align-items:center;">
          <div class = "flexRow flexCenter" style="margin:10px 0px;">
            <div class = "flexRow flexCenter blueBox">商家在后台创建群裂变活动</div>
          </div>
          <div class = "flexRow flexCenter" style="margin:10px 0px">
            <div class = "blueline"></div>
            <div class = "bluetriangle"></div>
            <div class = "flexRow flexCenter blueBox">活动开始，参与活动的客户群群主收到群发通知</div>
          </div>
          <div class = "flexRow flexCenter" style="margin:10px 0px">
            <div class = "blueline"></div>
            <div class = "bluetriangle"></div>
            <div class = "flexRow flexCenter blueBox">群主一键群发活动给客户A</div>
          </div>
          <div class = "flexRow flexCenter" style="margin:10px 0px">
            <div class = "blueline"></div>
            <div class = "bluetriangle"></div>
            <div class = "flexRow flexCenter blueBox">客户A点击活动链接生成自己的专属海报</div>
          </div>
          <div class = "flexRow flexCenter" style="margin:10px 0px">
            <div class = "blueline"></div>
            <div class = "bluetriangle"></div>
            <div class = "flexRow flexCenter blueBox">其他客户通过专属海报加入群聊，为客户A助力</div>
          </div>
          <div class = "flexRow flexCenter" style="margin:10px 0px">
            <div class = "blueline"></div>
            <div class = "bluetriangle"></div>
            <div class = "flexRow flexCenter blueBox">A完成任务后，进入链接领取活动奖品</div>
          </div>
        </div>
      </el-card>
      <el-card style="margin-top:10px">
        <div class = "flexRow" >
          <div class = "flexRow">
            <div class = "queryFont">关键字：</div>
            <el-input v-model.trim="keywords" placeholder="活动名称" style="width:180px;height:36px"></el-input>
          </div>
          <div class = "flexRow" style="margin:0px 15px">
            <div class = "queryFont">活动状态：</div>
            <el-select v-model="state" placeholder="请选择活动状态" style="width:180px;height:36px">
              <el-option v-for="(v,i) in statelist" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <div class = "flexRow">
            <el-button type="primary" style="height:36px" @click="query">查询</el-button>
            <buttonPermissions :datas="'groupfissionAdd'" style="margin-left:15px">
              <el-button type="primary" style="height:36px" @click="fissionEdit()">添加</el-button>
            </buttonPermissions>
          </div>
        </div>
        <div style="margin-top:10px">
          <el-table :data="tablelist" class = "table" v-loading = "tableloading" :header-row-style="{'height':'48px'}">
            <el-table-column prop="" label="活动名称" width="300px">
              <template slot-scope="scope">
                <div class = "fontHidden">
                  {{scope.row.ActivityName}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="活动时间" width="300px">
              <template slot-scope="scope">
                {{scope.row.StartTime}}至{{scope.row.EndTime}}
              </template>
            </el-table-column>
            <el-table-column prop="QyWeixinRealGroupCount" label="推广群聊">
              <template slot="header" slot-scope="scope">
								<div class="flexRow">
									<div>推广群聊</div>
									<el-tooltip class="item" effect="dark" content="参与本次活动的企业微信客户群" placement="top">
										<i class="el-icon-warning-outline"
											style="font-size:18px;margin:2px 0px 0px 5px"></i>
									</el-tooltip>
								</div>
							</template>
            </el-table-column>
            <!-- <el-table-column prop="" label="未群发员工">
              <template slot="header" slot-scope="scope">
								<div class="flexRow">
									<div>未群发员工</div>
									<el-tooltip class="item" effect="dark" content="参与本次活动的员工当中，还未群发活动信息给客户的员工数量" placement="top">
										<i class="el-icon-warning-outline"
											style="font-size:18px;margin:2px 0px 0px 5px"></i>
									</el-tooltip>
								</div>
							</template>
            </el-table-column> -->
            <el-table-column prop="MemberCount" label="参与客户数">
              <template slot="header" slot-scope="scope">
								<div class="flexRow">
									<div>参与客户数</div>
									<el-tooltip class="item" effect="dark" content="参与本次裂变活动的客户数量" placement="top">
										<i class="el-icon-warning-outline"
											style="font-size:18px;margin:2px 0px 0px 5px"></i>
									</el-tooltip>
								</div>
							</template>
            </el-table-column>
            <el-table-column prop="StateValue" label="活动状态"></el-table-column>
            <el-table-column prop="AddTime" label="创建时间"></el-table-column>
            <el-table-column prop="" label="操作" width="300px">
              <template slot-scope="scope">
                <div v-if="getstate(scope.row.StateValue) == 0">
                  <buttonPermissions :datas = "'groupfissionEdit'" style="margin-right:10px">
                    <el-button type="text" @click="fissionEdit(scope.row)">编辑</el-button>
                  </buttonPermissions>
                  <buttonPermissions :datas = "'groupfissionPromote'" style="margin-right:10px">
                    <el-button type="text" @click="fissionPromote(scope.row)">推广</el-button>
                  </buttonPermissions>
                  <buttonPermissions :datas = "'groupfissionCopy'" style="margin-right:10px">
                    <el-button type="text" @click="fissionCopy(scope.row)">复制</el-button>
                  </buttonPermissions>
                  <buttonPermissions :datas = "'groupfissionEnd'" style="margin-right:10px">
                    <el-button type="text" @click="fissionEnd(scope.row)">使终止</el-button>
                  </buttonPermissions>
                </div>
                <div v-if="getstate(scope.row.StateValue) == 1">
                  <buttonPermissions :datas = "'groupfissionEdit'" style="margin-right:10px">
                    <el-button type="text" @click="fissionEdit(scope.row)">编辑</el-button>
                  </buttonPermissions>
                  <buttonPermissions :datas = "'groupfissionData'" style="margin-right:10px">
                    <el-button type="text" @click="$router.push({path:'/Customer/groupfission/groupfissionData?Id='+scope.row.Id})">数据</el-button>
                  </buttonPermissions>
                  <buttonPermissions :datas = "'groupfissionPromote'" style="margin-right:10px">
                    <el-button type="text" @click="fissionPromote(scope.row)">推广</el-button>
                  </buttonPermissions>
                  <!-- 这里要判断 更多内的权限是否有开启  全部未开启则不显示更多 -->
                  <buttonPermissions2 :datas = "['groupfissionEnd','groupfissionCopy','groupfissioninformmaster']">
                    <el-dropdown trigger="click">
                      <el-button type="text" style="margin-right:10px">更多</el-button>
                      <el-dropdown-menu slot="dropdown" :ref="'dropdown'+scope.row.Id">
                        <el-dropdown-item>
                          <buttonPermissions :datas = "'groupfissioninformmaster'">
                            <el-button type="text" @click="groupfissioninformmaster(scope.row)">通知群主</el-button>
                          </buttonPermissions>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <buttonPermissions :datas = "'groupfissionEnd'">
                            <el-button type="text" @click="fissionEnd(scope.row)">使终止</el-button>
                          </buttonPermissions>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <buttonPermissions :datas = "'groupfissionCopy'">
                            <el-button type="text" @click="fissionCopy(scope.row)">复制</el-button>
                          </buttonPermissions>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </buttonPermissions2>
                </div>
                <div v-if="getstate(scope.row.StateValue) == 2">
                  <buttonPermissions :datas = "'groupfissionView'" style="margin-right:10px">
                    <el-button type="text" @click="fissionView(scope.row)">查看</el-button>
                  </buttonPermissions>
                  <buttonPermissions :datas = "'groupfissionData'" style="margin-right:10px">
                    <el-button type="text" @click="$router.push({path:'/Customer/groupfission/groupfissionData?Id='+scope.row.Id})">数据</el-button>
                  </buttonPermissions>
                  <buttonPermissions :datas = "'groupfissionCopy'" style="margin-right:10px">
                    <el-button type="text" @click="fissionCopy(scope.row)">复制</el-button>
                  </buttonPermissions>
                  <buttonPermissions :datas = "'groupfissionDelete'" style="margin-right:10px">
                    <el-button type="text" style="color:rgb(245,108,108)" @click="fissionDelete(scope.row)">删除</el-button>
                  </buttonPermissions>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top:10px;text-align:right">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="sizepage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-card>
    </div>

    <el-dialog :visible.sync="informshow" title="通知群主" custom-class="informclass" @closed = "staff=[]" v-loading = "informloading">
      <div class="flexRow" style="margin-left:30px">
        <div class = "informFont">
          请选择推广群聊的群主：
        </div>
        <div style="flex:1">
          <el-select class = "selectstaff" :loading="staffloading" v-model="staff" multiple filterable placeholder="请选择群主" style="width:400px;max-height:200px">
            <el-option v-for="(v,i) in stafflist" :key="i" :value="v.Id" :label="v.EmployeeName"></el-option>
          </el-select>
          <p class = "tipfont" style="width:80%">系统将自动给选择的群主创建企业微信群发任务，同时群主将会在企业微信-[客户群]中收到群发消息任务提醒。
            <span style="color:#f56c6c">群主需要在企业微信内手动点击[发送]</span>
          </p>
        </div>
      </div>
      <div style="text-align:right;margin-top:20px">
        <el-button @click="informshow=false">取消</el-button>
        <el-button type="primary" @click="btnaffirminform">确认通知</el-button>
      </div>
    </el-dialog>

    <qr-code :downloadData="windowCode" :qrCodeIshow="qrCodeIshow" :title="'推广群裂变活动'" :downFont="'下载活动二维码'"
    	@cancel="closeQrCode"></qr-code>
  </div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions';
import buttonPermissions2 from '@/components/buttonPermissions2';
import QrCode from "@/components/bathQrCodes"
import QrCodepop from "@/components/bathQrCodes2"
import{
  qyweixinqyweixingroupfissionactivitypagelist,
  qyweixinqyweixingroupfissionactivityfinish,
  qyweixinqyweixingroupfissionactivitydelete,
  qyweixinqyweixingroupfissionactivityemployeelist,
  qyweixinqyweixingroupfissionactivitycreatemsg,
  qyweixinsuiteauthinfo,
}from "@/api/sv1.0.0"
import {
		mapGetters
	} from "vuex"
export default {
  components:{
    QrCode,
    QrCodepop,
    buttonPermissions,
    buttonPermissions2
  },
  computed: {
			...mapGetters(['mallInfo','pcImg','ComponentId'])
	},
  data () {
    return {
      isnotQYWX:false,
      qrCodeIshow:false,
      pageloading:false,
      defaultLogo: 'https://cdn.dkycn.cn/melyshop/image/20210722-9726909c-ec6e-450d-b45b-43dcbaea5e8d.png',
      windowCode:{
        url: '',
				icon: '',
				name: '',
      },
      experienceCode:{
        url: 'https://cdn.dkycn.cn/melyshop/image/groupfissionCode.png',
				icon: '',
				name: '',
        link:'https://jusnn6k8al.feishu.cn/docs/doccnRqPD0A8P2WZggZVh8jWPkb',
      },
      keywords:'',
      state:null,
      statelist:[
        {value:null,label:'全部'},
        {value:0,label:'未开始'},
        {value:1,label:'进行中'},  
        {value:2,label:'已结束'},
      ],
      tablelist:[
        // {Id:2,StateValue:'进行中'}
      ],
      tableloading:false,
      currentPage:1,
      sizepage:20,
      total:null,

      message:{},
      informshow:false,
      informloading:false,
      staffloading:false,
      staff:[],
      stafflist:[]
    }
  },
  created () {
    // console.log(this.mallInfo)
    this.getData()
  },
  methods: {
    closeQrCode(){
      this.qrCodeIshow = false
    },
    getstate(e){
      if(e=='未开始'){
        return 0
      }else if(e=='进行中'){
        return 1
      }else if(e=='已结束'){
        return 2
      }
    },
    async getData() {
      this.pageloading = true
				try {
					const res = await qyweixinsuiteauthinfo();
          if(res.IsSuccess){
            this.isnotQYWX = res.Result.IsFinishQyWeixinSuiteStp
            // console.log(res.Result.IsFinishQyWeixinSuiteStp)
            if(this.isnotQYWX){
              this.gettablelist()
            }
          }
				}finally{
          this.pageloading = false
        }
		},

    //通知群主
    groupfissioninformmaster(e){
      this.message = e
      this.informshow = true
      this.getqyweixinqyweixingroupfissionactivityemployeelist()
    },
    //通知群主列表
    async getqyweixinqyweixingroupfissionactivityemployeelist(){
      // console.log(this.message)
      this.staffloading = true
      try{
        let data = {
          Id:this.message.Id
        }
        let result = await qyweixinqyweixingroupfissionactivityemployeelist(data)
        if(result.IsSuccess){
          // console.log(result)
          this.stafflist = result.Result
        }
      }finally{
        this.staffloading = false
      }
    },
    btnaffirminform(){
      if(this.staff&&this.staff.length){
        this.affirminform()
      }else{
        this.$message.error('请选择群主')
      }
    },
    //确认通知
    async affirminform(){
      this.informloading = true
      try{
        let data = {
          Id:this.message.Id,
          ChoseEmployeeIds:this.staff
        }
        let result = await qyweixinqyweixingroupfissionactivitycreatemsg(data)
        if(result.IsSuccess){
          // console.log(result)
          this.$message.success('操作成功')
          this.informshow = false
        }
      }finally{
        this.informloading = false
      }
    },

    //推广
    fissionPromote(e){
      // console.log(e)
      // var URL = window.location.href;
      // let h = URL.split(".")[0];
      // let domain = h.split("//")[1];
      // var secondParam = ''
      // if (process.env.NODE_ENV == 'development') {
      //   secondParam = 'xcs'//推手模式
      // } else {
      //   secondParam = domain
      // }
	  let secondParam = window.localStorage.getItem('mlmzMallKey')
      // let head = this.ComponentId==2?'https://mlwap.dkyapi.cn':'https://wapapi.qidian.shop'
      let head = 'https://wapapi.qidian.shop'
      this.windowCode = {
        url: head+'/welcome/#/pages/joinGroupActivity/joinGroupActivity?mallkey='+secondParam+'&id='+e.Id,
				icon: this.pcImg,
				name: e.ActivityName,
				// link: 'https://jusnn6k8al.feishu.cn/docs/doccnJebsyYAyIx4kjovOnZnhSc'
      }
      this.qrCodeIshow = true
    },
    //使终止
    fissionEnd(e){
      this.$confirm('活动终止后，你将不可再对这场活动进行编辑，客户点击活动链接或扫描活动二维码无法参与活动，是否确认使终止？','提示',{
        confirmButtonText: '确定',
          cancelButtonText: '关闭',
          type: 'warning'
      }).then(()=>{
        this.toqyweixinqyweixinfriendfissionactivityfinish(e)
      }).catch(()=>{})
    },
    async toqyweixinqyweixinfriendfissionactivityfinish(e){
      this.pageloading = true
      let Id = await e.Id
      try{
        let data = {
          Id:Id
        }
        let result = await qyweixinqyweixingroupfissionactivityfinish(data)
        if(result.IsSuccess){
          this.$message.success('操作成功')
          this.gettablelist()
        }
      }finally{
        this.pageloading = false
      }
    },
    //删除
    fissionDelete(e){
      this.$confirm('删除后不可恢复，是否确认删除本场活动？','提示',{
        confirmButtonText: '确定',
          cancelButtonText: '关闭',
          type: 'warning'
      }).then(()=>{
        this.toqyweixinqyweixinfriendfissionactivitydelete(e)
      }).catch(()=>{})
    },
    async toqyweixinqyweixinfriendfissionactivitydelete(e){
      this.pageloading = true
      let Id = await e.Id
      try{
        let data = {
          Id:Id
        }
        let result = await qyweixinqyweixingroupfissionactivitydelete(data)
        if(result.IsSuccess){
          this.$message.success('操作成功')
          this.gettablelist()
        }
      }finally{
        this.pageloading = false
      }
    },
    //提醒
    fissionRemind(e){
      this.$confirm('提醒成功后，系统将通过企业微信—[客户联系]给参与活动的员工推送群发提醒，员工接收到提醒后，需要手动点击发送。是否确认提醒？','提示',{
        confirmButtonText: '确定',
          cancelButtonText: '关闭',
          type: 'warning'
      }).then(()=>{

      }).catch(()=>{})
    },
    //查看
    fissionView(e){
      let Id = 0
      console.log(e)
      if(e){
        Id = e.Id
      }
      this.$router.push({
        path:'/Customer/groupfission/groupfissionEdit',
        query:{
          Id:Id,
        }
      })
    },
    //复制
    fissionCopy(e){
      this.$router.push({
        path:'/Customer/groupfission/groupfissionEdit?Id='+e.Id+'&iscopy=true'
      })
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.sizepage = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    jumplink(){
      this.$router.push({
        path:'/saleChannel/qyWechat'
      })
    },
    query(){
      this.currentPage=1
      this.gettablelist()
      // console.log('查询')
    },
    //编辑
    fissionEdit(e){
      // console.log('添加')
      let Id = 0
      if(e){
        Id = e.Id
      }
      this.$router.push({
        path:'/Customer/groupfission/groupfissionEdit',
        query:{
          Id:Id,
        }
      })
    },
    async gettablelist(){
      this.tableloading = true
      try{
        let data = {
          Keywords:this.keywords,
          ActivityState:this.state,
          Skip:(this.currentPage-1)*this.sizepage,
          Take:this.sizepage
        }
        let result = await qyweixinqyweixingroupfissionactivitypagelist(data)
        if(result.IsSuccess){
          // console.log(result.Result)
          this.tablelist = result.Result.Results
          this.total = result.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
  }
}
</script>

<style lang = "less" scoped>
  .cardfixed{ 
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 10px;
    left: 10px;
  }
  .overshow{
    overflow: hidden;
    overflow-y: auto;
  }
  .flexRow{
    display: flex;
    flex-direction: row;
  }
  .betweenCenter{
    justify-content: space-between;
    align-items: center;
  }
  .flexCenter{
    align-items: center;
    justify-content:center
  }
  .theFont{
    font-size: 12px;
		color: #999;
		margin-top: 10px;
  }
  .titFont{
    font-size: 14px;
    color: #303133;
  }
  .fontHidden{
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .flexCol{
    display: flex;
    flex-direction: column;
  }
  .flexevenly{
    justify-content: space-evenly;
  }
  .blueBox{
    font-size:14px;
    border:1px solid #409eff;
    border-radius: 2px;
    background: #FBFDFF;
    min-height: 60px;
    max-height: 80px;
    min-width: 150px;
    max-width: 220px;
    padding: 10px 30px;
  }
  .blueline{
    width: 50px;
    height: 1px;
    background: #409eff;
  }
  .bluetriangle{
    border-left:10px solid #409eff;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
  .queryFont{
    font-weight:bold;
    line-height:37px;
    font-size:14px;
    display: inline-block;
    color: #606266;
  }
  .desc-title{
		font-weight: bold;
		margin-top: 30px;
		font-size: 16px;
		margin-bottom: 10px;
	}
  .informFont{
    font-size: 15px;
    line-height: 36px;
  }
  .tipfont{
    font-size: 12px;
    color: #909399;
    line-height: 1.3;
  }
  .selectstaff ::v-deep .el-select__tags{
    max-height: 100px !important;
    overflow: hidden auto;
  }
</style>
<style>
  .table .el-table__body td,.el-table__body th{
    padding:0px !important;
    height: 48px;
  }
  .informclass .el-dialog__body{
    padding: 20px;
  }
</style>